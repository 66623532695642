//@extend-elements
//original selectors
//.box.dark-gray, table.dark-gray, table td.dark-gray, div.dark-gray, .btn.dark-gray, footer, footer a
%extend_1 {
	background-color: $color_storm_dust_approx;
	color: $brand-white;
	text-decoration: none;
}

//original selectors
//.sort-column-1, .sort-column-2
%extend_2 {
	position: absolute;
	top: 5px;
	left: 5px;
}


.black {
	color: $brand-dark-gray;
}
.maroon {
	color: $brand-red;
}
.light-maroon {
	color: $color_oriental_pink_approx;
}
.lighter-maroon {
	color: $color_oyster_pink_approx;
}
.barely-maroon {
}
.white {
	color: $brand-white;
}
.light-gray {
	color: $concrete;
}
.gray {
	color: $brand-lighter-gray;
}
.medium-gray {
	color: $color_silver_chalice_approx;
}
.dark-gray {
	color: $color_storm_dust_approx;
}
.black-bg {
	background-color: $black;
}
.maroon-bg {
	background-color: $brand-red;
}
.light-maroon-bg {
	background-color: $color_oriental_pink_approx;
}
.lighter-maroon-bg {
	background-color: $color_oyster_pink_approx;
}
.barely-maroon-bg {
}
.white-bg {
	background-color: $brand-white;
}
.light-gray-bg {
	background-color: $concrete;
}
.gray-bg {
	background-color: $brand-lighter-gray;
	hr.border-bottom.dotted {
		border-bottom-color: $color_iron_approx;
		margin-top: -5px;
	}
}
.medium-gray-bg {
	background-color: $color_silver_chalice_approx;
}
.dark-gray-bg {
	background-color: $color_storm_dust_approx;
}

.bg-dark-gray {
  background-color: $brand-dark-gray !important;
}

.georgia {
	font-family: $georgia, $font_1, $font_2;
}
.arial {
	font-family: $font_3, $font_4;
}
.btn {
	font-size: 16px;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 0;
	background-color: $brand-red;
	color: $brand-white;
	//Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	text-shadow: none;
	text-decoration: none;
	padding-top: 9px;
	padding-bottom: 9px;
	font-family: $font_3, $font_4;
	&.btn-sm {
		padding-top: 2px;
		padding-bottom: 2px;
	}
	&:focus {
		color: $brand-white;
	}
	&:hover {
		background-color: $brand-lighter-gray;
		color: $brand-red;
	}
	&.black {
		background-color: $black;
		color: $brand-white;
	}
	&.maroon {
		background-color: $brand-red;
		color: $brand-white;
	}
	&.light-gray {
		background-color: $concrete;
		color: $black;
	}
	&.gray {
		background-color: $brand-lighter-gray;
		color: $black;
	}
	&.dark-gray {
		@extend %extend_1;
	}
	&.wide {
		padding-left: 60px;
		padding-right: 60px;
	} 
}
.box {
	font-family: $georgia, $font_1, $font_2;
	padding: 15px;
	margin-bottom: 15px;
	&.black {
		background-color: rgba($black, 0.8);
		color: $brand-white;
		&.content-b {
			width: 45%;
			padding: 30px;
      @media screen and (max-width: 400px) {
        width: 100%;
      }
		}
	}
	&.maroon {
		background-color: $brand-red;
		color: $brand-white;
		border-top: 15px solid $brand-lighter-gray;
		margin-bottom: 0;
	}
	&.light-gray {
		background-color: $concrete;
		color: $black;
	}
	&.gray {
		background-color: $brand-lighter-gray;
		color: $black;
	}
	&.dark-gray {
		@extend %extend_1;
	}
	&.sub {
		position: absolute;
		bottom: -120px;
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: bottom .5s;
		width: 100%;
		background-color: $color_oriental_pink_approx;
		padding: 0;
		left: 0;
		margin-bottom: 0;
		z-index: -1;
		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
		li {
			border-bottom: 1px dotted $color_eunry_approx;
			list-style-type: none;
			margin-left: 5px;
			font-family: $font_3, $font_4;
			padding: 3px 9px;
			color: $brand-white;
			text-decoration: none;
			> a {
				color: $brand-white;
				text-decoration: none;
			}
		}
	}
	&.transition {
		//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
		transition: background-color 1s;
		position: relative;
		&:hover {
			background-color: $color_oriental_pink_approx;
			cursor: pointer;
			.box.sub {
				bottom: -1px;
				z-index: 0;
			}
		}
	}
	p {
		font-size: 16px;
	}
}
table {
	&.black {
		background-color: $black;
		color: $brand-white;
	}
	td {
		&.black {
			background-color: $black;
			color: $brand-white;
		}
		&.maroon {
			background-color: $brand-red;
			color: $brand-white;
		}
		&.light-gray {
			background-color: $concrete;
			color: $black;
		}
		&.gray {
			background-color: $brand-lighter-gray;
			color: $black;
		}
		&.dark-gray {
			@extend %extend_1;
		}
	}
	&.maroon {
		background-color: $brand-red;
		color: $brand-white;
	}
	&.light-gray {
		background-color: $concrete;
		color: $black;
	}
	&.gray {
		background-color: $brand-lighter-gray;
		color: $black;
	}
	&.dark-gray {
		@extend %extend_1;
	}
}
div {
	&.black {
		background-color: $black;
		color: $brand-white;
	}
	&.maroon {
		background-color: $brand-red;
		color: $brand-white;
	}
	&.light-gray {
		background-color: $concrete;
		color: $black;
	}
	&.gray {
		background-color: $brand-lighter-gray;
		color: $black;
	}
	&.dark-gray {
		@extend %extend_1;
	}
}
footer {
	//@extend %extend_1;
	font-family: $font_3;
	font-size: 14px;
	a {
		//@extend %extend_1;
		//color: $brand-white;
		&:hover {
			text-decoration: none;
			color: $black;
		}
	}
}
h1 {
	font-size: 34px;
	margin-top: 30px;
	margin-bottom: 20px;
	font-family: $georgia, $font_1, $font_2;
	&#page-title {
		font-size: 40px;
	}
}
h2 {
	font-size: 36px;
	font-family: $georgia, $font_1, $font_2;
}
h3 {
	font-size: 26px;
	font-family: $georgia, $font_1, $font_2;
}
h4 {
	font-size: 24px;
	font-family: $georgia, $font_1, $font_2;
}
h5 {
	font-family: $georgia, $font_1, $font_2;
}
h6 {
	font-family: $georgia, $font_1, $font_2;
}
a {
	color: $brand-red;
	text-decoration: underline;
}
.jumbo {
	padding: 20px 15%;
  @media screen and (max-width: 767px) {
    padding: 30px;
  }
}

header {
	padding: 25px 0;
  padding-top: 50px;
  padding-bottom: 15px;
}
@media screen and (max-width: 767px) {
  header {
    padding: 14px 20px;
  }
}
.img-thumb {
	min-width: 100%;
}
hr {
	border: none;
	border-bottom: 1px dashed $brand-gray;
}
.error-message {
	color: red;
}
.sortable-header {
	position: relative;
}
.sort-arrow-wrapper {
	position: relative;
}
.sort-column-0 {
	position: absolute;
	top: 55px;
	left: 2px;
}
.sort-column-1 {
	@extend %extend_2;
}
.sort-column-2 {
	@extend %extend_2;
}
.pos-r {
	position: relative !important;
}
.pos-a {
	position: absolute !important;
}
.pos-f {
	position: fixed !important;
}
.w-sm {
	width: 25% !important;
}
.w-md {
	width: 50% !important;
}
.w-lg {
	width: 75% !important;
}
.w-full {
	width: 100% !important;
}
.m-a-0 {
	margin: 0 !important;
}
.m-t-0 {
	margin-top: 0 !important;
}
.m-r-0 {
	margin-right: 0 !important;
}
.m-b-0 {
	margin-bottom: 0 !important;
}
.m-l-0 {
	margin-left: 0 !important;
}
.m-x-0 {
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.m-y-0 {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}
.m-a {
	margin: 20px !important;
}
.m-t {
	margin-top: 20px !important;
}
.m-r {
	margin-right: 20px !important;
}
.m-b {
	margin-bottom: 20px !important;
}
.m-l {
	margin-left: 20px !important;
}
.m-x {
	margin-right: 20px !important;
	margin-left: 20px !important;
}
.m-y {
	margin-top: 20px !important;
	margin-bottom: 20px !important;
}
.m-x-auto {
	margin-right: auto !important;
	margin-left: auto !important;
}
.m-a-md {
	margin: 30px !important;
}
.m-t-md {
	margin-top: 30px !important;
}
.m-r-md {
	margin-right: 30px !important;
}
.m-b-md {
	margin-bottom: 30px !important;
}
.m-l-md {
	margin-left: 30px !important;
}
.m-x-md {
	margin-right: 30px !important;
	margin-left: 30px !important;
}
.m-y-md {
	margin-top: 30px !important;
	margin-bottom: 30px !important;
}
.m-a-lg {
	margin: 60px !important;
}
.m-t-lg {
	margin-top: 60px !important;
}
.m-r-lg {
	margin-right: 60px !important;
}
.m-b-lg {
	margin-bottom: 60px !important;
}
.m-l-lg {
	margin-left: 60px !important;
}
.m-x-lg {
	margin-right: 60px !important;
	margin-left: 60px !important;
}
.m-y-lg {
	margin-top: 60px !important;
	margin-bottom: 60px !important;
}
.p-a-0 {
	padding: 0 !important;
}
.p-t-0 {
	padding-top: 0 !important;
}
.p-r-0 {
	padding-right: 0 !important;
}
.p-b-0 {
	padding-bottom: 0 !important;
}
.p-l-0 {
	padding-left: 0 !important;
}
.p-x-0 {
	padding-left: 0 !important;
	padding-right: 0 !important;
}
.p-y-0 {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}
.p-a {
	padding: 20px !important;
}
.p-t {
	padding-top: 20px !important;
}
.p-r {
	padding-right: 20px !important;
}
.p-b {
	padding-bottom: 20px !important;
}
.p-l {
	padding-left: 20px !important;
}
.p-x {
	padding-right: 20px !important;
	padding-left: 20px !important;
}
.p-y {
	padding-top: 20px !important;
	padding-bottom: 20px !important;
}
.p-a-md {
	padding: 30px !important;
}
.p-t-md {
	padding-top: 30px !important;
}
.p-r-md {
	padding-right: 30px !important;
}
.p-b-md {
	padding-bottom: 30px !important;
}
.p-l-md {
	padding-left: 30px !important;
}
.p-x-md {
	padding-right: 30px !important;
	padding-left: 30px !important;
}
.p-y-md {
	padding-top: 30px !important;
	padding-bottom: 30px !important;
}
.p-a-lg {
	padding: 60px !important;
}
.p-t-lg {
	padding-top: 60px !important;
}
.p-r-lg {
	padding-right: 60px !important;
}
.p-b-lg {
	padding-bottom: 60px !important;
}
.p-l-lg {
	padding-left: 60px !important;
}
.p-x-lg {
	padding-right: 60px !important;
	padding-left: 60px !important;
}
.p-y-lg {
	padding-top: 60px !important;
	padding-bottom: 60px !important;
}
.pagination {
	> li {
		> a {
			color: $brand-red;
		}
		> span {
			color: $brand-red;
		}
	}
	> .active {
		> a {
			background-color: $brand-red;
			border-color: $color_mountain_mist_approx;
			&:focus {
				background-color: $brand-red;
				border-color: $color_mountain_mist_approx;
			}
			&:hover {
				background-color: $brand-red;
				border-color: $color_mountain_mist_approx;
			}
		}
		> span {
			background-color: $brand-red;
			border-color: $color_mountain_mist_approx;
			&:focus {
				background-color: $brand-red;
				border-color: $color_mountain_mist_approx;
			}
			&:hover {
				background-color: $brand-red;
				border-color: $color_mountain_mist_approx;
			}
		}
	}
}
@media screen and(min-width: 767px) {
	.triptych {
		.col-lg-1 {
			padding-right: 0;
		}
		.col-lg-10 {
			padding-right: 0;
		}
		.col-lg-11 {
			padding-right: 0;
		}
		.col-lg-12 {
			padding-right: 0;
		}
		.col-lg-2 {
			padding-right: 0;
		}
		.col-lg-3 {
			padding-right: 0;
		}
		.col-lg-4 {
			padding-right: 0;
		}
		.col-lg-5 {
			padding-right: 0;
		}
		.col-lg-6 {
			padding-right: 0;
		}
		.col-lg-7 {
			padding-right: 0;
		}
		.col-lg-8 {
			padding-right: 0;
		}
		.col-lg-9 {
			padding-right: 0;
		}
		.col-md-1 {
			padding-right: 0;
		}
		.col-md-10 {
			padding-right: 0;
		}
		.col-md-11 {
			padding-right: 0;
		}
		.col-md-12 {
			padding-right: 0;
		}
		.col-md-2 {
			padding-right: 0;
		}
		.col-md-3 {
			padding-right: 0;
		}
		.col-md-4 {
			padding-right: 0;
		}
		.col-md-5 {
			padding-right: 0;
		}
		.col-md-6 {
			padding-right: 0;
		}
		.col-md-7 {
			padding-right: 0;
		}
		.col-md-8 {
			padding-right: 0;
		}
		.col-md-9 {
			padding-right: 0;
		}
		.col-sm-1 {
			padding-right: 0;
		}
		.col-sm-10 {
			padding-right: 0;
		}
		.col-sm-11 {
			padding-right: 0;
		}
		.col-sm-12 {
			padding-right: 0;
		}
		.col-sm-2 {
			padding-right: 0;
		}
		.col-sm-3 {
			padding-right: 0;
		}
		.col-sm-4 {
			padding-right: 0;
		}
		.col-sm-5 {
			padding-right: 0;
		}
		.col-sm-6 {
			padding-right: 0;
		}
		.col-sm-7 {
			padding-right: 0;
		}
		.col-sm-8 {
			padding-right: 0;
		}
		.col-sm-9 {
			padding-right: 0;
		}
	}
}
.table {
	th {
		background-color: $concrete;
	}
	> tbody > tr {
		> td {
			border-top: 1px dotted $brand-gray;
		}
		> th {
			border-top: 1px dotted $brand-gray;
		}
	}
	> tfoot > tr {
		> td {
			border-top: 1px dotted $brand-gray;
		}
		> th {
			border-top: 1px dotted $brand-gray;
		}
	}
	> thead > tr {
		> td {
			border-top: 1px dotted $brand-gray;
		}
		> th {
			border-top: 1px dotted $brand-gray;
		}
	}
}
input[type="checkbox"], input[type="radio"] {
	display: none;
	+ label {
		display: inline-block;
		width: 19px;
		height: 19px;
		margin: -1px 4px 0 0;
		vertical-align: middle;
		background: $url_0 left top no-repeat;
		cursor: pointer;
		white-space: nowrap;
	}
	&:checked + label {
		background: $url_0 -19px top no-repeat;
		span {
			color: $brand-black;
		}
	}
}
.row-question {
	.hover-checkbox.unchecked {
		opacity: 0;
	}
	&:hover .cds-checkbox {
	}
}
.dontknow {
	label {
		font-size: 14px;
		padding-top: 20px;
		> span {
			display: inline-block;
			margin-left: -29px;
		}
	}
	&.unchecked {
		opacity: 0.3;
	}
}
.trulydifferentiating-section-label {
	padding-left: 24px;
}
.disabled {
	opacity: 0.5;
	cursor: not-allowed !important;
	label {
		opacity: 0.5;
		cursor: not-allowed !important;
	}
}
.cds-checkbox-label {
	padding-left: 19px;
}
body {
	color: black;
	font-family: $font_3, $font_4;
	font-size: 16px;
	&.home #page-title {
		display: none;
	}
}
.tagline {
	font-family: $georgia, $font_1, $font_2;
	font-size: 112%;
	white-space: nowrap;
}
.divider {
	font-family: $georgia, $font_1, $font_2;
	margin: 0 16px;
	font-size: 146%;
}
.callout {
	font-family: $georgia, $font_1, $font_2;
}
.login-cue {
	font-family: $georgia, $font_1, $font_2;
	margin-top: 8px;
}
.buffer-top {
	margin-top: 50px;
}
.buffer-bottom {
	margin-bottom: 50px;
}
.buffer-top-small {
	margin-top: 25px;
}
.buffer-bottom-small {
	margin-bottom: 25px;
}
.buffer-top-mini {
	margin-top: 10px;
}
.buffer-bottom-mini {
	margin-bottom: 10px;
}
.padding-top {
	padding-top: 50px;
}
.padding-bottom {
	padding-bottom: 50px;
}
.padding-top-small {
	padding-top: 25px;
}
.padding-bottom-small {
	padding-bottom: 25px;
}
.padding-top-mini {
	padding-top: 10px;
}
.padding-bottom-mini {
	padding-bottom: 10px;
}
.border-bottom {
	border-bottom: 1px solid $brand-lighter-gray;
	&.thick {
		border-bottom: 4px solid $black;
		&.gray {
			border-bottom: 2px solid $color_iron_approx;
		}
	}
	&.dotted {
		border-bottom-style: dashed;
	}
}
.border-bottom-thick {
	border-bottom: 2px solid $brand-lighter-gray;
}
.border-top {
	border-top: 1px solid $brand-lighter-gray;
}
.fixed-height {
	min-height: 130px;
}
.fixed-height-large {
	min-height: 440px;
}
@media screen and(max-width: 400px) {
	.bubble-label {
		display: none;
	}
	.bubble-chart {
		padding: 0;
		padding-bottom: 20px;
	}
}
.parallax-bg {
	background: $url_1 no-repeat center 25%;
	background-size: 100%;
	width: 100%;
	height: 320px;
}
.jumbotron {
	height: 500px;
	color: $brand-white;
	//Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
	text-shadow: $color_tundora_approx 0 1px 1px;
	background: transparent;
}
.expert-bio-synopsis {
	padding: 20px;
	padding-top: 20px;
}

@media screen and(min-width: 768px) {
	.padding-left-small {
		padding-left: 40px;
	}
	.spanner {
		min-width: 105.4%;
	}
	.nav-tabs.nav-justified.nav-results {
		a {
			border-bottom: 0;
			border-right: 6px solid $brand-white;
			border-top: 1px solid $brand-white;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
		}
		> .active > a {
			border-bottom: 0;
			border-right: 6px solid $brand-white;
			border-top: 1px solid $brand-white;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&:focus {
				border-bottom: 0;
				border-right: 6px solid $brand-white;
				border-top: 1px solid $brand-white;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
			}
			&:hover {
				border-bottom: 0;
				border-right: 6px solid $brand-white;
				border-top: 1px solid $brand-white;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
			}
		}
	}
}

.show-later {
	display: none;
}
.img-responsive {
	display: inline-block;
}
.nav-results {
	border-bottom: 4px solid $brand-red !important;
	ul {
		padding-bottom: 0 !important;
	}
	li {
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		background-color: $brand-gray;
		color: $brand-white;
		text-decoration: none;
		color: $brand-red;
		> a {
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			background-color: $brand-gray;
			color: $brand-white;
			text-decoration: none;
			&:hover {
				color: $brand-red;
			}
		}
		&.active {
			background-color: $brand-red;
			color: $brand-white;
			border-bottom: 0;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			a {
				background-color: $brand-red;
				color: $brand-white;
				border-bottom: 0;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
				&:active {
					background-color: $brand-red;
					color: $brand-white;
					border-bottom: 0;
					//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
					border-radius: 0;
				}
				&:focus {
					background-color: $brand-red;
					color: $brand-white;
					border-bottom: 0;
					//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
					border-radius: 0;
				}
			}
		}
	}
}
@media screen and(min-width: 1200px) {
	.nav-tabs.nav-justified.nav-results > li > a {
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 0;
		padding-left: 7px;
		padding-right: 7px;
		border-bottom: 0;
	}
	.best-in-class-hidden.nav-tabs.nav-justified.nav-results > li > a {
		padding-left: 30px;
		padding-right: 30px;
	}
}
@media screen and(min-width: 401px) and(max-width: 767px) {
}
@media screen and(min-width: 768px) and(max-width: 991px) {
	.larger {
		font-size: 120%;
	}
	.bordered-right-dotted-y {
		background-position: 85.5% top;
	}
	.bubble-strip-y {
		height: 290px;
	}
	.bubble-strip-x {
		width: 487px;
	}
	.nav-tabs.nav-justified.nav-results > li > a {
		min-height: 60px;
		padding-bottom: 0;
		padding-left: 7px;
		padding-right: 7px;
	}
	.best-in-class-hidden.nav-tabs.nav-justified.nav-results > li > a {
		padding-left: 30px;
		padding-right: 30px;
	}
}
@media screen and(min-width: 992px) and(max-width: 1199px) {
	.larger {
		font-size: 130%;
	}
	.bordered-right-dotted-y {
		background-position: 76.5% top;
	}
	.bubble-strip-y {
		height: 417px;
	}
	.bubble-strip-x {
		width: 705px;
	}
	.nav-tabs.nav-justified.nav-results > li > a {
		padding-left: 29px;
		padding-right: 29px;
	}
	.best-in-class-hidden.nav-tabs.nav-justified.nav-results > li > a {
		padding-left: 30px;
		padding-right: 30px;
	}
}
.btn-sm {
	font-size: 12px;
}
.na {
	color: $color_alto_approx;
}
.smaller {
	font-size: 90%;
}
.larger {
	font-size: 140%;
}
.highlight {
	background-color: $yellow;
}
.thin {
	font-weight: 100;
}
.border-left {
	border-left: 1px solid $brand-gray;
	&.dotted {
		border-left-color: $color_silver_chalice_approx;
		border-left-style: dashed;
		border-left-width: 2px;
	}
}
.bordered-right-dotted-y {
	background: $brand-white $url_2 repeat-y 76.6% top;
}
.bubble-strip-y {
	position: absolute;
	height: 535px;
	width: 117px;
	left: 20px;
	top: 48px;
	overflow: hidden !important;
	.sub-label {
		text-align: right;
		width: 100%;
	}
}
.bubble-strip-x {
	position: absolute;
	width: 903px;
	bottom: -10px;
	left: 158px;
	height: 50px;
	overflow: hidden !important;
}
.bubble-strip-overflow-y {
	height: 100%;
	width: 100%;
	position: absolute;
	bottom: 0;
	overflow: hidden !important;
}
.bubble-strip-overflow-x {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden !important;
}
.bubble-strip-offset-y {
	position: absolute;
	width: 100%;
	height: 100%;
}
.bubble-strip-offset-x {
	position: absolute;
	width: 100%;
	height: 100%;
}
.bubble-label {
	position: absolute;
	font-weight: bold;
	&.y-differentiating {
		top: 0;
	}
	&.y-important {
		top: calc(33% -(13px / 2));
	}
	&.y-somewhatimportant {
		top: calc(67% -(62px / 2));
	}
	&.y-notimportant {
		bottom: 0;
	}
	&.x-wellbelow {
		text-align: left;
		width: 124px;
	}
	&.x-industryaverage {
		left: calc(50% -(122px / 2));
	}
	&.x-wellabove {
		text-align: right;
		right: 0;
		width: 140px;
	}
	&.sub-label {
		font-weight: normal;
	}
	&.importance {
		text-align: right;
		top: 62.5%;
		width: 140px;
		left: 5px;
		//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
		transform: rotate(-90deg);
		//Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
		transform-origin: left center 0;
	}
	&.proficiency {
		text-align: center;
		left: 33%;
		width: 460px;
		bottom: -30px;
	}
}
.bubble-chart {
	padding-left: 130px;
	padding-right: 70px;
	padding-top: 20px;
	padding-bottom: 30px;
	position: relative;
	margin-bottom: 30px;
}
.mouseover-cue {
	padding-left: 27px;
}
@media screen and(min-width: 401px) {
	.download-image {
		padding-left: 157px;
	}
	.legend-wrapper {
		padding-left: 157px;
		line-height: 1.1em;
	}
}
@media screen and(max-width: 767px) {
	.bordered-right-dotted-y {
		background: none;
	}
}
.key-label {
	margin-left: 55px;
	margin-bottom: 7px;
}
.key-line {
	clear: both;
	margin-bottom: 15px;
}
.key-wrapper {
	padding-right: 80px;
	line-height: 1.1em;
}
.key {
	border: 1px solid $black;
	width: 34px;
	height: 34px;
	display: inline-block;
	float: left;
	margin-right: 10px;
	&.key-above-x-equals-y {
		background: $color_blossom_approx;
	}
	&.key-below-x-equals-y {
		background: $color_bizarre_approx;
	}
	&.circular {
		//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
		border-radius: 1000px;
		width: 24px;
		height: 24px;
		float: left;
		margin-right: 10px;
		margin-bottom: 12px;
	}
}
.question {
	font-size: 19px;
}
.capabilities-list {
	color: $black;
	text-decoration: none;
	font-size: 17px;
	a {
		color: $black;
		text-decoration: none;
		font-size: 17px;
	}
}
.progress-bar {
	color: $color_pink_swan_approx;
}
.nlp-response {
	font-family: $font_5, $font_3, $font_4;
	font-size: 56px;
	padding: 100px;
	font-weight: 100;
}
.pwd-check {
	.short {
		font-weight: bold;
		color: red;
	}
	.weak {
		font-weight: bold;
		color: $web_orange;
	}
	.good {
		font-weight: bold;
		color: $color_dodger_blue_approx;
	}
	.strong {
		font-weight: bold;
		color: $color_japanese_laurel_approx;
	}
}
@media screen and(min-width: 992px) {
	#client-poc-pdf-download {
		margin-top: -65px;
	}
}
tr {
	&.disabled {
		background-color: $color_alto_approx;
		opacity: 1;
	}
	&.faded {
		opacity: 0.5 !important;
	}
	&.strikethrough .question-name {
		text-decoration: line-through !important;
	}
}
.circle-border {
	border: 1px solid $brand-red;
	//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
	border-radius: 50px;
}
.fixit-jump-placeholder {
	display: none;
	height: 90px;
}
.btn[disabled="disabled"]:hover {
	background-color: $brand-red;
	color: $brand-white;
}
.cds-checkbox label > span {
	color: $brand-dark-gray;
}
.daytoday label {
	font-size: 14px;
	font-weight: normal;
	padding-left: 19px;
}
.trulydifferentiating label {
	font-size: 14px;
	padding-top: 20px;
	> span {
		display: inline-block;
		margin-left: -39px;
	}
}
.checkbox label {
	padding-left: 19px;
}
.breadcrumbs ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	li {
		display: inline;
		&:not(:last-child) a:after {
			content: " >";
			padding: 0 12px 0 15px;
			color: $color_mountain_mist_approx;
		}
	}
}
.nav a {
	text-decoration: none;
	outline: none;
}
.nav-tabs.nav-justified {
	&.nav-results {
		a {
			border-bottom: 0;
			border-top: 1px solid $brand-white;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
		}
		> .active > a {
			border-bottom: 0;
			border-top: 1px solid $brand-white;
			//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
			border-radius: 0;
			&:focus {
				border-bottom: 0;
				border-top: 1px solid $brand-white;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
			}
			&:hover {
				border-bottom: 0;
				border-top: 1px solid $brand-white;
				//Instead of the line below you could use @include border-radius($radius, $vertical-radius)
				border-radius: 0;
			}
		}
		li:last-child {
			a {
				border-right: 0 !important;
			}
			> .active > a {
				border-right: 0 !important;
				&:focus {
					border-right: 0 !important;
				}
				&:hover {
					border-right: 0 !important;
				}
				&:active {
					border-right: 0 !important;
				}
			}
		}
	}
	> .active > a {
		border: 0;
		border-image-width: 0;
		&:focus {
			border: 0;
			border-image-width: 0;
		}
		&:hover {
			border: 0;
			border-image-width: 0;
		}
	}
}
.btn-group .btn {
	color: $brand-red;
}
img.n-header {
	margin-top: 19px;
	margin-left: 5px;
	margin-bottom: 3px;
}
#confirm-password-wrapper .error {
	color: red;
}
.part-1.fixit-jump-placeholder {
	height: 45px;
}
.part-1a.fixit-jump-placeholder {
	height: 70px;
}
part-2.fixit-jump-placeholder {
	height: 90px;
}

.main-panel-display {
  background-color: $brand-light-gray;
}

@media screen and (max-width: 767px) {
  .row {
    margin: 0;
  }
  .container {
    padding: 0;
  }
}

.page-title-wrapper {
  background-color: black;
  color: white;
  padding: 10px 20px;
  h1 {
    margin: 0;
    font-size: 28px !important;
  }
}

.content-panel {
  padding: 10px 50px 50px;
}

.article-box {
  background-color: #f6f6f6 !important;
  color: #404041;
  margin-bottom: 30px;
  line-height: 1.3em;
  h3 {
    font-size: 24px !important;
  }
  .article-type {
    font-family: $georgia, $font_1, $font_2;
    color: #8e8e8e;
  }
}