.share-bar {
	background: $brand-dark-gray;
	color: $brand-white;
	font-style: italic;
	img {
		display: inline-block;
	}
  @media screen and (max-width: 767px) {
    padding: 0 20px;
    .container {
      h4 {
        text-align: center;
      }
    }
    .share-bar-divider {
      padding-top: 20px;
    }
  }
}