/**
 * Results page.
 */

body.results {

  #page-title {
    display: none;
  }
  
  h3 {
    font-size: 28px;
    &.coherence-score {
      line-height: 36px;
      padding-bottom: 20px;
    }
  }
  
  .background {
    background-size: cover;
  }
  
  .feature {
    
    @media screen and (max-width: 767px) {
      padding: 10px 20px;
    }
    
    @media screen and (min-width: 768px) and (max-width: 991px) {
      padding: 20px 150px;
    }
    
    @media screen and (min-width: 992px) and (max-width: 1199px) {
      padding: 30px 150px;
    }
    
    @media screen and (min-width: 1200px) {
      padding: 40px 150px;
    }
    
  }
  
  .pdf-results-download {

  }
  
  .contact-us-feature {

  }
  
  .OutputView__learnMore___2edaj {
  	margin: 32px 0 64px;
  	background: $color_pumice_approx;
  	padding: 25px;
  	&:after {
  		content: ".";
  		clear: both;
  		display: block;
  		visibility: hidden;
  		height: 0;
  	}
  }
  .OutputView__contactUs___1lBBB {
  	padding-top: 20px;
  	padding-bottom: 20px;
  	color: $brand-white;
  	margin-top: 50px;
  	margin-bottom: 50px;
  	background: $brand-red;
  	.OutputView__contactText___20E2p {
  		padding-left: 150px;
  		padding-top: 25px;
  		min-height: 165px;
  		cursor: pointer;
  		color: $brand-white;
	
  	}
  	.OutputView__downloadText___2a_XD {
  		padding-left: 150px;
  		padding-top: 25px;
  		min-height: 165px;
  		cursor: pointer;
  		color: $brand-white;
  	}
  	h4 {
  		font-size: 16px;
  		font-weight: 400;
  		font-family: $font_4, $font_1;
  		padding: 0;
  		margin: 0;
  	}
  	p {
  		font-size: 16px;
  		font-weight: 400;
  		font-family: $font_4, $font_1;
  		padding: 0;
  		margin: 0;
  		font-size: 35px;
  		font-style: italic;
  	}
  }
  .OutputView__download___1eeKG {
  	padding-top: 20px;
  	padding-bottom: 20px;
  	color: $brand-white;
  	margin-top: 50px;
  	margin-bottom: 50px;
  	background: $color_chicago_approx;
  	.OutputView__contactText___20E2p {
  		padding-left: 150px;
  		padding-top: 25px;
  		min-height: 165px;
  		cursor: pointer;
  		color: $brand-white;
  	}
  	.OutputView__downloadText___2a_XD {
  		padding-left: 150px;
  		padding-top: 25px;
  		min-height: 165px;
  		cursor: pointer;
  		color: $brand-white;
  		padding-top: 50px;

  	}
  	h4 {
  		font-size: 16px;
  		font-weight: 400;
  		font-family: $font_4, $font_1;
  		padding: 0;
  		margin: 0;
  	}
  	p {
  		font-size: 16px;
  		font-weight: 400;
  		font-family: $font_4, $font_1;
  		padding: 0;
  		margin: 0;
  		font-size: 35px;
  		font-style: italic;
  	}
  }
  .OutputView__relatedContent___39c6l {
  	&:after {
  		content: ".";
  		clear: both;
  		display: block;
  		visibility: hidden;
  		height: 0;
  	}
  	div {
  		background: $concrete;
  		float: left;
  		width: 270px;
  		margin-right: 20px;
  		cursor: pointer;
  		&:last-child {
  			margin-right: 0;
  		}
  		img {
  			width: 270px;
  		}
  		h3 {
  			padding: 0 20px;
  			margin-bottom: 0;
  			margin-top: 10px;
  			font-family: $font_4, $font_1;
  			font-weight: 400;
  			font-style: italic;
  			font-size: 24px;
  			color: $brand-red;
  		}
  		h4 {
  			padding: 0 20px;
  			margin-bottom: 0;
  			margin-top: 10px;
  			font-family: $font_4, $font_1;
  			font-weight: 400;
  			font-style: italic;
  			color: $color_stack_approx;
  			font-size: 15px;
  		}
  		p {
  			padding: 0 20px;
  			margin-bottom: 0;
  			margin-top: 10px;
  			padding-bottom: 20px;
  			font-size: 14px;
  			color: $brand-dark-gray;
  		}
  	}
  }
  .OutputView__typeLinks___17ebk {
  	list-style: none;
  	margin: 0;
  	float: left;
  	max-width: 175px;
  	margin-right: 30px;
  	padding: 0;
  	&:last-child {
  		margin-right: 0;
  	}
  	li {
  		font-size: 16px;
  		cursor: pointer;
  		margin-bottom: 1em;
  		color: $brand-red;
  		text-decoration: underline;
  		&.OutputView__headline___2LKwA {
  			font-weight: 700;
  			cursor: default;
  			color: $black;
  			text-decoration: none;
  		}
  	}
  }
  
}

/**
 * certain pages should not show title bar
 */
body.results,
body.home,
body.archetypes,
body.survey-finish {
  .page-title-wrapper {
    display: none;
  }
} 

body.login,
body.survey-part-1,
body.survey-demographics,
body.results {
  .language-dropdown-wrapper {
    display: none;
  }
  .divider {
    display: none;
  }
}

a.pdf-download-results {
   color: white; 
   text-decoration: none;
}

.client-logo {
  margin-top: -20px;
  img {
    max-height: 53px;
    max-width: 200px;
  }
}