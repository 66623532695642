.radial-chart-container {
	position: relative;
	margin: 0 auto;
	width: 300px;
  height: 306px;
}
.radial-chart-inner {
	position: absolute;
	top: 50%;
	margin-top: -83px;
	width: 100%;
	text-align: center;
  font-family: Georgia;
  font-style: italic;
	p {
		max-width: 130px;
		font-size: 14px;
		padding: 0;
		margin: 0 auto;
	}
	em {
		color: $brand-red;
	}
}
.radial-chart-score {
	color: $brand-red;
	font-size: 42px;
	margin-top: 25px;
	display: inline-block;
  font-weight: bold;
}
.radial-chart-labels {
	position: absolute;
	top: 26px;
	right: 160px;
	font-size: 10px;
	text-align: right;
	margin-left: -120px;
	span {
		display: block;
		color: $color_silver_chalice_approx;
		span {
			padding: 1px 0 0;
		}
	}
	.radial-chart-company {
		color: $color_scarpa_flow_approx;
	}
}




