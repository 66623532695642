body.survey-finish, body.results {
  .main-panel-display {
    background: $brand-white;
  }
  
  .triptych {
    .well {
      box-shadow: none;
      border: 0;
      background: $brand-gray;
      border-radius: 0;
    }
  }
  
  .more-link {
    float: right;
    text-align: right;
  }
  
  .chart-icon {
    img {
      max-width: 120px;
    }
    text-align: center;
  }
  
  .easyPieChart {
    margin-top: -8px;
    margin-bottom: -10px;
    @media screen and (max-width: 767px) {
      margin-top: 40px;
      left: calc(50% + 25px - 100%);
    }
    z-index: 1000;
    canvas {
      border-radius: 50%;
      background: $brand-white;
    }
    line-height: 129px !important;
  }
  
  .chart .percentage span {
    font-family: $georgia;
    font-weight: normal;
    color: $brand-red;
  }
  
  .dashboard-block, .dashboard-block-flex {
    position: relative;
    .more-link {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 20px;
    }
  }
  
  .dashboard-block {
    @media screen and (min-width: 768px) {
      min-height: 278px;
    }
    p {
      margin-top: 15px;
    }
  }
  
  .nav-pills {
    font-family: $georgia;
  }
  .nav-pills>li.active>a, 
  .nav-pills>li.active>a:focus, 
  .nav-pills>li.active>a:hover {
    background: $brand-red;
    color: $brand-white;
  }
  .nav-pills>li>a:focus, .nav-pills>li>a:hover {
    color: $brand-black;
  }
}

body.results {
  .results-nav-wrapper {
    margin-top: 10px;
  }
}




