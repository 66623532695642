ul.archetypes-key, ul.legend {
  list-style-type: none;
  li {
    list-style-type: none;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .swatch {
    width: 20px;
    height: 20px;
    background: $brand-gray;
    float: left;
    margin-right: 10px;
  }
}

.archetypes-key,
.legend,
.disk-chart {
  
  .disk-segment {
    height: 40px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $brand-black;
    float: left;
    margin-bottom: 20px;
    margin-top: -10px;
    
  }
  &.large {
    .disk-segment {
      height: 60px;
    }
  }
  &.tiny {
    .disk-segment {
      margin-bottom: 0;
      margin-top: 0;
      display: block;
      float: none;
      text-align: left;
      color: #ffffff;
      height: 26px;
      padding: 3px 6px;
      &.zero {
        color: $brand-red;
        background: none !important;
      }
    }
  }
  .adrift {
    background: #e0301e !important;
  }
  .untapped {
    background: #eb8c00 !important;
  }
  .stretched {
    background: #ffb600 !important;
  }
  .restless {
    background: #ffda7f !important;
  }
  .contender {
    background: #66a397 !important;
  }
  .supercompetitor {
    background: #006652 !important;
  }
  
  .red {
    background: $brand-red;
    color: $brand-white;
  }
  .gray {
    background: $brand-white;
  }
  
  .compare-1 {
    background: $brand-red !important;
  }
  .compare-2 {
    background: $brand-gold !important;
  }
}

ul.legend {
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 30px;
  li {
    margin-left: 0;
    padding-left: 0;
  }
}
