div.form {
  padding: 10px 50px 50px;
}

.panel-body {
  background-color: #f2f2f2;
}

.content {
  table {
    border: 1px solid #ddd;
    margin-bottom: 0;
    td {
      background: $brand-white;
    }
  }
}

.input.datetime select {
  @extend .form-control;
  margin-right: 10px;
}

#bulk-links-form button {
  float: left;
  margin-right: 10px;
  margin-left: 10px;
}

.survey-finish {
  .tab-pane {
    h1 {
      margin-top: 0;
    }
  }
}

.question-options {
  label {
    float: left;
    margin-right: 15px;
    margin-top: 11px;
  }
  input {
    width: 97%;
  }
}