.archetype-chart {
  margin: 50px 0;
  position: relative;
  
  .your-archetype {
    position: absolute;
    left: 0;
    top: 0;
    padding: 4%;
    font-family: Georgia;
    font-style: italic;
    font-size: 100%;
    width: 55%;
    span {
      display: block;
      &.title {
        font-weight: bold;
        font-size: 150%;
        line-height: 1.2em;
      }
      &.description {
        color: $brand-red;
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
    }
  }
  
  .archetype-type {
    position: absolute;
    left: 0;
    top: 0;
    width: 13%;
    height: 150px;
    
    &:hover {
      cursor: pointer;
    }
    
    a {
      text-decoration: none;
    }
    
    &.active {
      span.title {
        color: $color_storm_dust_approx;
      }
      svg path { fill: $brand-red !important; }
    }
    
    .label-wrapper {
      font-family: Georgia;
      font-style: italic;
      font-size: 90%;
      width: 300%;
      span {
        color: #999;
        
        &.description {
          background: rgba(255, 255, 255, 0.97);
//        border-radius: 10px;
          padding: 10px;
          opacity: 0;
          margin-top: -10000px;
          @include transition(opacity .5s);
          @media screen and (max-width: 767px) {
            font-size: 9px;
          }
        }
      }
      
      span {
        display: block;
        &.title {
          font-weight: bold;
          font-size: 150%;
          @media screen and (max-width: 767px) {
            line-height: 1em;
            margin-top: 2px;
          }
        }
      }
    }
    
    &.adrift {
      top: 73%;
      left: 21%;
      .label-wrapper {
        margin-top: -44%;
        margin-left: 100%;
        width: 340%;
      }
      &:hover {
        span.description {
          margin-top: 0;
          opacity: 1;
        }
      }
    }
    
    &.untapped {
      top: 54%;
      left: 53%;
      .label-wrapper {
        margin-top: -21%;
        margin-left: 85%;
        width: 260%;
      }
      &:hover {
        span.description {
          margin-top: 0;
          opacity: 1;
        }
      }
    }
    
    &.stretched {
      top: 49%;
      left: 34%;
      .label-wrapper {
        margin-top: -28%;
        margin-left: -111%;
        width: 280%;
      }
      &:hover {
        span.description {
          margin-top: 0;
          opacity: 1;
        }
      }
    }
    
    &.restless {
      top: 36%;
      left: 18%;
      .label-wrapper {
        margin-top: -28%;
        margin-left: -111%;
        width: 150%;
      }
      &:hover {
        span.description {
          margin-top: 0;
          opacity: 1;
        }
      }
    }
    
    &.contender {
      top: 17%;
      left: 50%;
      .label-wrapper {
        margin-top: -23%;
        margin-left: -110%;
        width: 220%;
      }
      &:hover {
        span.description {
          margin-top: 0;
          opacity: 1;
        }
      }
    }
    
    &.supercompetitor {
      top: 4%;
      left: 67%;
      width: 19%;
      .label-wrapper {
        width: 174%;
      }
      &:hover {
        span.description {
          margin-top: 0;
          opacity: 1;
        }
      }
    }
    
  }
}

@media screen and (max-width: 767px) {
  .archetype-chart {
    .archetype-type {
      .label-wrapper {
        span.title {
          font-size: 12px;
        }
      }
    }
  }
    
  .archetype-chart {
    span.title {
      font-size: 12px;
    }
  }
}

.archetype-icon {
  background: $brand-red;
  border-radius: 1000px;
  text-align: center;
  width: 150px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    
    width: 90px;
    path { 
      fill: $brand-white !important; 
    }
  }
}

.archetype-chart {
  &.no-hover {
    .archetype-type:hover {
      span.description {
        opacity: 0 !important;
      }
    }
  }
}