.form-control {
	background-color: white;
	box-shadow: none;
	border-radius: 0;
	border: none;
	margin-bottom: 15px;
	height: 44px;
}

@media screen and(min-width: 768px) {
	.form-inline .form-control {
		margin-top: 15px;
	}
}

fieldset {
	margin-bottom: 10px;
}

legend {
	margin-top: 10px;
}