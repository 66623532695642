$inner-question-padding: 100px;

body.survey-part-1 {
  .page-title-wrapper {
    padding: 10px 60px;
    @media screen and (max-width: 767px) {
      padding: 10px 35px;
    }
  }
}

.survey-wrapper {
  
  .item-question-header {
    background-color: $brand-medium-gray;
    color: white;
    padding: 10px 60px;
    padding-right: 170px;
    min-height: 150px; // prevent clock from spilling over
    @media screen and (max-width: 767px) {
      padding: 10px 35px;
    }
    border-bottom: 2px solid white;
  }

  .value-prop-wrapper {
    margin: 10px 0;
    color: #dedede;
    font-size: 14px;
  }
  
  .item {
    .item-question {
      @media screen and (min-width: 768px) {
        min-height: 375px;
      }
      .glyphicon {
        color: $brand-red;
      }
      .survey-question {
        font-size: 24px;
        @media screen and (max-width: 767px) {
          font-size: 18px;
          margin: 0;
          margin-bottom: 10px;
        }
      }
      .item-options {
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          padding: 20px;
          @media screen and (min-width: 768px) {
            padding-left: 60px;
          }
          background-color: #e7e7e7;
          border-bottom: 2px solid #ffffff;
          &.active {
            background-color: $brand-red;
            color: #ffffff;
            label {
              span {
                color: #ffffff;
                font-weight: normal;
              }
            }
          }
          label {
            height: auto;
            span {
              font-weight: normal;
              padding-left: 30px;
              white-space: normal;
              display: block;
              @media screen and (max-width: 767px) {
                width: 330px;
              }
              @media screen and (min-width: 768px) and (max-width: 991px) {
                width: 768px - $inner-question-padding;
              }
              @media screen and (min-width: 992px) and (max-width: 1199px) {
                width: 992px - $inner-question-padding;
              }
              @media screen and (min-width: 1200px) {
                width: 1150px - $inner-question-padding;
              }
            }
          }
        }
      }
      .row {

        .col-md-8 {
          @media screen and (min-width: 1200px) {
            width: 57%;
          }
        }
        
        .col-md-9 {
          @media screen and (min-width: 1200px) {
            width: 70%;
          }
        }
        
        .col-md-10 {
          @media screen and (min-width: 1200px) {
            width: 77%;
          }
          @media screen and (min-width: 992px) and (max-width: 1199px) {
            width: 78%;
          }
        }
        
        .col-md-1 {
          @media screen and (max-width: 400px) {
            label {
              margin-left: 34px;
              height: 52px;
              margin-top: 6px;
              span {
                font-size: 14px;
                margin-left: -52px;
                padding-left: 20px;
                margin-top: 20px;
                display: block !important;
                width: 100px;
                white-space: normal;
                text-align: center;
                font-weight: normal;
              }
            }
          }
          
          @media screen and (min-width: 768px) and (max-width: 991px) {
            label {
              span {
                padding-left: 20px;
              }
            }
          }
          
          @media screen and (min-width: 992px) and (max-width: 1199px) {
            font-size: 12px;
          }
          
          @media screen and (min-width: 1200px) {
            width: 10%;
          }
        }
        
        .col-md-offset-10 {
          @media screen and (min-width: 992px) and (max-width: 1199px) {
            margin-left: 77%;
          }
          
          @media screen and (min-width: 1200px) {
            margin-left: 76%;
          }
        }
        
        .col-md-offset-9 {
          @media screen and (min-width: 992px) and (max-width: 1199px) {
            margin-left: 71%;
          }
          
          @media screen and (min-width: 1200px) {
            margin-left: 66%;
          }
        }
        
        .col-md-offset-8 {
          @media screen and (min-width: 992px) and (max-width: 1199px) {
            margin-left: 65.5%;
          }
          
          @media screen and (min-width: 1200px) {
            margin-left: 56%;
          }
        }
      }
      .grid-row {
        padding: 20px;
        background-color: #e7e7e7;
        border-bottom: 2px solid #ffffff;
      }
      .grid-row-header {
        padding: 8px;
        background-color: #e7e7e7;
        border-bottom: 2px solid #ffffff;
      }
    }
    .specify-other .form-control {
      margin-top: 10px;
      background-color: #ffffff !important;
    }
    
    .coh-type-essay {
      background-color: #e7e7e7;
      padding: 30px 60px;
      textarea {
        min-height: 100px;
      }
    }
    
  }
}

@media screen and (max-width: 767px) {
  .controls {
    margin-top: 15px;
    padding: 20px;
    .wide {
      padding: 10px 40px;
    }
  }
}