.footer-nav-primary {
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	li {
		float: left;
		width: 14.28%;
		font-family: $georgia, $font_1, $font_2;
	}
	a {
		padding-top: 10px;
		border-top: 4px solid $brand-white;
		display: block;
		margin-right: 20px;
	}
}

@media screen and(max-width: 767px) {
	.footer-nav-primary {
		li {
			float: none;
			width: 100%;
		}
		a {
			border-top: 1px solid $brand-medium-gray;
			margin-right: 0;
			padding: 5px 0;
		}
	}
	.boilerplate .logo {
		width: 120px;
    margin: 0 auto;
		padding: 0;
    margin-bottom: 20px;
	}
}
.boilerplate {
  color: #666;
  font-size: 13px;
  a {
    color: $brand-medium-gray;
  }
	.logo {
    img {
      width: 130px;
    }
	}
//  .content {
//    @media screen and (max-width: 767px) {
//      text-align: center;
//    }
//  }
}
.footer-nav-secondary {
  padding-top: 20px;
  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
		display: inline;
    
	}
	li {
		display: inline;
		padding-right: 22px;
		float: left;
	}
	a {
		display: inline;
	}
}