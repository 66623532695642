$language-picker-width: 135px;

.language-dropdown-wrapper {
  
  min-width: $language-picker-width;
  padding-top: 8px;
  img {
    width: 40px;
    margin-top: -3px;
  }
  
  font-family: Georgia;

  max-height: 36px;
  @media screen and (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    text-align: right;
    width: 50%;
    max-width: 50%;
  }
  
  @media screen and (max-width: 991px) {
    
    min-width: $language-picker-width;
    margin-top: -35px;
    
    .current-language {
      width: $language-picker-width;
      margin: 0 5px;
    }
    .current-language, .language-options, .language-option {
      float: right;
      display: block;
      padding-bottom: 15px;
    }
    .language-options {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.2s linear;
      width: $language-picker-width;
      box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
      &.language-options-showing {
        visibility: visible;
        opacity: 1;
      }
    }
    .language-option {
      margin: 0;
      background-color: white;
      padding: 15px 5px;
      border-top: 1px solid #f2f2f2;
      width: 100%;
      text-align: right;
      a {
        text-decoration: none;
      }
    }

  }
  
  @media screen and (min-width: 992px) {
    width: 66px;
    .current-language {
      padding: 0 15px;
      cursor: pointer;
    }
    .language-options {
      a {
        text-decoration: none;
      }
      position: relative;
      z-index: 9999;
      padding-top: 13px;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.2s linear;
      background: white;
      box-shadow: 4px 4px 4px rgba(0,0,0,0.1);
      width: $language-picker-width;
      &.language-options-showing {
        visibility: visible;
        opacity: 1;
      }
      .language-option {
        &:hover {
          background-color: #f2f2f2;
        };
        border-top: 1px solid rgba(white, 0.2);
        padding: 10px 15px;
      }
    }
  }
  
}