ul.nav-sub {
  li, li.active {
    background-color: $brand-lighter-gray;
    &.active, &.focus {
      background: $brand-lighter-gray;
      border-bottom: 5px solid $brand-red;
      a, a.active, a.focus {
        background: $brand-lighter-gray;
        border: none !important;
        color: $brand-red;
        border-right-color: $brand-lighter-gray;
      }
    }
    a, a.active, a.focus, .active > a {
      border: none !important;
      font-family: $georgia;
      font-size: 26px;
      color: $brand-medium-gray;
      background-color: $brand-lighter-gray;
      
    }
  }
  a:focus, a:hover {
    background-color: $brand-lighter-gray !important;
  }
  
  border-bottom-color: $brand-dark-gray !important;
  border-bottom-width: 2px !important;
  
  margin-bottom: 15px;
  
}


