//colors

$brand-white: #ffffff;
$brand-black: #000000;
$brand-dark-gray: #404041;
$brand-medium-gray: #72777b;
$brand-gray: #cccccc;
$brand-light-gray: #dedede;
$brand-lighter-gray: #e7e7e7;
$brand-red: #82141e;
$brand-gold: #968c6d;

$color_mountain_mist_approx: #999;
$color_oriental_pink_approx: #c0898e;
$color_oyster_pink_approx: #e6d0d2;
$concrete: #f2f2f2;
$color_silver_chalice_approx: #aaa;
$color_storm_dust_approx: #666666;
$black: #000000;
$color_eunry_approx: #d0999e;
$color_tundora_approx: #444;
$color_alto_approx: #ddd;
$yellow: yellow;
$color_iron_approx: #d6d6d6;
$color_blossom_approx: #d7b3b7;
$color_bizarre_approx: #ecdcdd;
$color_pink_swan_approx: #bbbbbb;
$web_orange: orange;
$color_dodger_blue_approx: #2d98f3;
$color_japanese_laurel_approx: green;
$color_alto_approx: #dedede;
$color_heath_approx: #560d14;
$silver:  silver;
$black_80: rgba(0,0,0,.8);
$color_silver_sand_approx: #c2c2c2;
$color_silver_approx: #c1c1c1;
$color_cararra_approx: #ebebeb;
$color_scarpa_flow_approx: #53565b;
$color_matrix_approx: #a95b62;
$color_chicago_approx: #575756;
$color_tawny_port_approx: #6d2339;
$color_stack_approx: #8e8e8e;
$color_pumice_approx: #c7c7c7;
$color_boston_blue_approx: #4095bf;
$gallery: #efefef;
$color_gray_nurse_approx: #e9e9e9;



//fonts
$georgia: Georgia;
$font_1: Times New Roman;
$font_2: serif;
$font_3: Arial;
$font_4: sans-serif;
$font_5: Helvetica;

//urls
$url_0: url(../img/check_radio_sheet.png);
$url_1: url(../img/hero.jpg);
$url_2: url(../img/border-dotted-y.png);

$font-georgia: $georgia;
$font-arial: $font_3;