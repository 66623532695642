$font-size-h1: 42px;
$font-size-h3: 32px;

@media screen and (max-width: 767px) {
  .clock-wrapper-desktop {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .clock-wrapper-mobile {
    display: none !important;
  }
}

.easyPieChart {
  position: relative !important;
  text-align: center;
  @media screen and (max-width: 767px) {
    float: right;
    text-align: right;
  }
}

.easyPieChart canvas {
    position: absolute;
    top: 10px;
    left: calc(50% - 60px);
    z-index: -1000;
}

.percentage {
  width: auto !important;
  height: auto !important;
}

.percentage,
.label {
    text-align: center;
    margin-bottom: 0.1em;
}

/* --- real CSS begins here --- */

.chart {
  .percentage {
    span {
      font-weight: bold;
      font-size: $font-size-h1;
    }
  }
}

.clock-wrapper {
  &.clock-wrapper-desktop {
    margin-top: 5px;
    background-color: $brand-medium-gray;
    height: 130px;
    width: 160px;
    margin-left: -160px;
    position: relative;
    z-index: 1000;
    .clock {
      span {
        font-weight: bold;
        line-height: 32px;
        color: white;
      }
      font-size: 23px;
      font-family: $georgia;
      font-style: italic;
      text-align: center;
      position: absolute;
      z-index: -1000;
      left: calc(50% - 60px);
      top: 10px;
      width: 117px;
      line-height: 14px;
      padding-top: 25px;
      small {
        line-height: 11px;
        font-size: 13px;
        color: white;
      }
    }
    &.counter {
      .clock {
        padding-top: 40px;
        small {
          font-size: 15px;
        }
      }
    }
  }
  &.clock-wrapper-mobile {
    margin-top: 5px;
    height: 130px;
    width: 160px;
    margin-left: -160px;
    position: relative;
    z-index: 1000;
    .clock {
      span {
        color: white;
        font-size: 13px;
      }
      font-size: 23px;
      font-family: $georgia;
      font-style: italic;
      text-align: center;
      position: absolute;
      z-index: -1000;
      left: calc(50% - 104px);
      top: 0px;
      width: 117px;
      line-height: 14px;
      padding-top: 10px;
      small {
        line-height: 11px;
        font-size: 13px;
        color: white;
      }
    }
    &.counter {
      .clock {
        span {
          font-weight: bold;
        }
      }
    }
  }
}



